<template>
  <v-app>
    <v-main class="pa-0">
      <v-container
        class="fill-height background"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view />
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
export default {
  components: {
    Snackbar: () => import('@/components/shared/global/Snackbar'),
  },
};
</script>

<style></style>
